@tailwind base;
@tailwind components;
@tailwind utilities;

::-moz-selection,
::selection {
  background: rgb(75, 89, 99);
}

html {
  height: 100%;
}

body {
  @apply bg-slate-100;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  height: 100%;
}

.dark body {
  @apply bg-slate-900;
}

#root {
  height: 100%;
}
